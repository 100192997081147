import { AccessTokenExpiredError, AccountTypeAndTokenNotMatchError, InvalidAuthQueryParamsError, AccountNotAllowLoginWithTypeError, AccountInactiveError, AccountLockedError, AccountNotExistedError, WrongUsernameOrPasswordError, NotAllowAccountUseAppError, AccountNotAllowUseFeatureError, AuthInternalServerError } from "@features/auth/core/domain/login/models/version-2/auth-error-version2.model";


export const ERROR_CODE = {
  AccessTokenExpiredError: AccessTokenExpiredError._code,
  AccountTypeAndTokenNotMatchError: AccountTypeAndTokenNotMatchError._code,
  InvalidAuthQueryParamsError: InvalidAuthQueryParamsError._code, //
  AccountNotAllowLoginWithTypeError: AccountNotAllowLoginWithTypeError._code,

  AccountInactiveError: AccountInactiveError._code,
  AccountLockedError: AccountLockedError._code,
  AccountNotExistedError: AccountNotExistedError._code,
  WrongUsernameOrPasswordError: WrongUsernameOrPasswordError._code,
  NotAllowAccountUseAppError: NotAllowAccountUseAppError._code,
  AccountNotAllowUseFeatureError: AccountNotAllowUseFeatureError._code,

  AuthInternalServerError: AuthInternalServerError._code, // DEFAULT
}
