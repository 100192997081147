import { Injectable, inject, isDevMode } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '@features/auth/core/domain/login/auth-version-2.service';
import { lastValueFrom } from 'rxjs';
import {
  ACCOUNT_TYPE_QUERY_PARAMS,
  LOGIN_BY_TOKEN_ROUTE_QUERY,
} from './route-query-params.variable';
import {
  AccessTokenExpiredError,
  AccountNotAllowUseFeatureError,
  AccountTypeAndTokenNotMatchError,
  AuthInternalServerError,
  InvalidAuthQueryParamsError,
} from '@features/auth/core/domain/login/models/version-2/auth-error-version2.model';
import { StorageHelper } from '@features/auth/shared/helpers/storage.helper';
import { CoreAuthConfigService } from '@features/auth/core-auth-config.service';
import { AuthVersion1Service } from '../domain/login/auth-version-1.service';
import { NgbModal } from '@shared/components/lib-ng/lib-ng-bootstrap/modal/modal';

/**
 * Lớp guard này kiểm tra xem người dùng có mã thông báo truy cập hoạt động hay không
 * và chuyển hướng đến trang đăng nhập nếu không.
 * Nó triển khai giao diện CanActivate để sử dụng như một guard route trong các ứng dụng Angular.
 */
@Injectable()
export class AccessTokenGuard {
  router = inject(Router);

  conf = inject(CoreAuthConfigService);

  authService = inject(AuthService);
  authServiceV1 = inject(AuthVersion1Service);
  modalService = inject(NgbModal)


  isValidLoginByToken(token: string, redirect: string) {
    return token.trim().length !== 0 && redirect.trim().length !== 0;
  }

  getQueryParams(route: ActivatedRouteSnapshot) {
    const accessToken =
      (route.queryParams[LOGIN_BY_TOKEN_ROUTE_QUERY.TOKEN] as string) ?? '';
    const accessToken3th =
      (route.queryParams[LOGIN_BY_TOKEN_ROUTE_QUERY.TOKEN_3TH] as string) ?? '';
    let redirect =
      (route.queryParams[LOGIN_BY_TOKEN_ROUTE_QUERY.REDIRECT] as string) ?? '';
    const accountType =
      (route.queryParams[LOGIN_BY_TOKEN_ROUTE_QUERY.ACCOUNT_TYPE] as string) ??
      '';

    return {
      accessToken,
      accessToken3th,
      redirect,
      accountType,
    };
  }

  private handleLoginByTokenFailure = (redirect: string, code?: number) => {
    this.router.navigate(['login'], { queryParams: { er: code } });
  };

  handleLoginByTokenSuccess(token: string, redirect: string) {
    if (token) {
      this.router.navigate(['cap-nhat-thong-tin']);
    } else {
      this.handleLoginByTokenFailure(redirect);
    }
  }

  handleDefaultError(error: unknown, redirect: string) {
    if (error instanceof InvalidAuthQueryParamsError) {
      this.handleLoginByTokenFailure(
        redirect,
        InvalidAuthQueryParamsError._code
      );
    }
    if (error instanceof AccessTokenExpiredError) {
      this.handleLoginByTokenFailure(redirect, AccessTokenExpiredError._code);
    }
    if (error instanceof AccountTypeAndTokenNotMatchError) {
      this.handleLoginByTokenFailure(
        redirect,
        AccountTypeAndTokenNotMatchError._code
      );
    }
    if (error instanceof AuthInternalServerError) {
      this.handleLoginByTokenFailure(redirect, AuthInternalServerError._code);
    }
    if (error instanceof AccountNotAllowUseFeatureError) {
      this.handleLoginByTokenFailure(
        redirect,
        AccountNotAllowUseFeatureError._code
      );
    }
  }

  // ex: http://localhost:4200?redirect=/permission/api&acc-type=nv&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJ1c2VybmFtZSI6IjEyM0BnbWFpbC5jb20iLCJsb2FpX3RhaV9raG9hbiI6Ik5IQU5fVklFTiIsImlwIjoiOjpmZmZmOjE3Mi4xOS4wLjEiLCJhcHAiOiI4IiwidG9rZW4iOiIiLCJkZXZpY2UiOnsib3MiOnsibmFtZSI6IlVidW50dSIsInZlcnNpb24iOiIiLCJzaG9ydF9uYW1lIjoiVUJUIiwicGxhdGZvcm0iOiJ4NjQiLCJmYW1pbHkiOiJHTlUvTGludXgifSwiY2xpZW50Ijp7InR5cGUiOiJicm93c2VyIiwibmFtZSI6IkZpcmVmb3giLCJzaG9ydF9uYW1lIjoiRkYiLCJ2ZXJzaW9uIjoiMTI1LjAiLCJlbmdpbmUiOiJHZWNrbyIsImVuZ2luZV92ZXJzaW9uIjoiMTI1LjAiLCJmYW1pbHkiOiJGaXJlZm94In0sImRldmljZSI6eyJpZCI6IiIsInR5cGUiOiJkZXNrdG9wIiwiYnJhbmQiOiIiLCJtb2RlbCI6IiIsImNvZGUiOiIifX0sImlhdCI6MTcxNDYzNzg4OH0.eepL8gWYTjlo3zvl4ozaDPEw-xU4LntRaWN_ALvf7Us
  async handleLoginByToken(
    redirect: string,
    accessToken: string,
    accountType: string
  ) {
    try {
      const loginResult = await lastValueFrom(
        this.authService.handleAuthenticateByToken(accessToken, accountType)
      );

      this.handleLoginByTokenSuccess(loginResult.token, redirect);
    } catch (error) {
      this.handleDefaultError(error, redirect);
      console.error(error);
    }
  }
  // ex: http://localhost:4200?redirect=/ticket-management/sinh-vien/yeu-cau-ho-tro&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjE5MzciLCJOaGFuVmllbklEIjoiTk5WMDA1MDU4OCIsImFwcCI6Ik1PQklMRV9IVVRFQ0giLCJpcCI6IjEiLCJzZXJ2aWNlX2lkIjpudWxsLCJpYXQiOjE3MTc3NzI2NjU1MzMsInR5cGUiOiJMT0dJTl9UT0tFTiIsImV4cCI6MTcxNzc3MjY2NTU1OH0.AosCmQwqXrrMnzUFLUzMJa1baLrLvAYM9ci3TV1a7n4
  async handleLoginByTokenAuthV1(
    redirect: string,
    accessToken: string,
  ) {
    try {
      await lastValueFrom(
        this.authServiceV1.handleAuthenticateByToken(accessToken)
      );
      this.router.navigateByUrl(redirect);
    } catch (error) {
      this.handleDefaultError(error, redirect);
      console.error(error);
    }
  }

  // http://localhost:4200?redirect=permission/app&acc-type=nv&token-3th=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJ1c2VybmFtZSI6IjEyM0BnbWFpbC5jb20iLCJsb2FpX3RhaV9raG9hbiI6Ik5IQU5fVklFTiIsImlwIjoiOjpmZmZmOjE3Mi4xOS4wLjEiLCJhcHAiOiI4IiwidG9rZW4iOiIiLCJkZXZpY2UiOnsib3MiOnsibmFtZSI6IlVidW50dSIsInZlcnNpb24iOiIiLCJzaG9ydF9uYW1lIjoiVUJUIiwicGxhdGZvcm0iOiJ4NjQiLCJmYW1pbHkiOiJHTlUvTGludXgifSwiY2xpZW50Ijp7InR5cGUiOiJicm93c2VyIiwibmFtZSI6IkZpcmVmb3giLCJzaG9ydF9uYW1lIjoiRkYiLCJ2ZXJzaW9uIjoiMTI1LjAiLCJlbmdpbmUiOiJHZWNrbyIsImVuZ2luZV92ZXJzaW9uIjoiMTI1LjAiLCJmYW1pbHkiOiJGaXJlZm94In0sImRldmljZSI6eyJpZCI6IiIsInR5cGUiOiJkZXNrdG9wIiwiYnJhbmQiOiIiLCJtb2RlbCI6IiIsImNvZGUiOiIifX0sImlhdCI6MTcxNDYzNzg4OH0.eepL8gWYTjlo3zvl4ozaDPEw-xU4LntRaWN_ALvf7Us
  async handleLoginByTokenThirdParty(
    redirect: string,
    accessToken: string,
    accountType: string
  ) {
    isDevMode() && console.group(`Handle login by third party token`);
    try {
      const loginResult = await lastValueFrom(
        this.authService.handleAuthenticateByTokenThirdParty(
          accessToken,
          accountType
        )
      );
      this.handleLoginByTokenSuccess(loginResult.token, redirect);
    } catch (error) {
      this.handleDefaultError(error, redirect);
      console.error(error);
    }
    isDevMode() && console.groupEnd();
  }

  /**
   * Xác định xem người dùng có được phép truy cập vào route yêu cầu hay không.
   *
   * @param route - Route cần kích hoạt.
   * @param state - Router state snapshot cho route.
   * @returns Một Promise trả về giá trị boolean hoặc UrlTree xác định liệu có thể kích hoạt route hay không.
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    let { accessToken, accessToken3th, redirect, accountType } =
      this.getQueryParams(route);

    if (redirect) StorageHelper.storeCallbackURL(redirect);

    // Logic login by token
    if (this.isValidLoginByToken(redirect, accessToken)) {

      if (this.conf.isAuthV2()) {
        await this.handleLoginByToken(redirect, accessToken, accountType);
      }

      if (this.conf.isAuthV1()) {
        await this.handleLoginByTokenAuthV1(redirect, accessToken);
      }

      return true;
    } // Login by token third party
    else if (this.isValidLoginByToken(redirect, accessToken3th)) {
      await this.handleLoginByTokenThirdParty(
        redirect,
        accessToken3th,
        accountType
      );
      return true;
    } else if (!this.authService.isLoggedIn()) {
      this.modalService.dismissAll();
      // UPGRADE: if have config useCustomLoginPageURL: > navigate to this page
      if (this.conf?.applicationConfig?.useCustomLoginPageURL) {
        window.location.href = this.conf?.applicationConfig?.useCustomLoginPageURL;
      } else {
        this.router.navigateByUrl('/login');
      }
      return false;
    }
    return true;
  }
}

/**
 * Hàm này trả về phương thức canActivate của lớp AccessTokenGuard để sử dụng như một guard route.
 *
 * @param route - Route cần kích hoạt.
 * @param state - Router state snapshot cho route.
 * @returns Một Promise trả về giá trị boolean hoặc UrlTree xác định liệu có thể kích hoạt route hay không.
 */
export const CanActivateAccessTokenGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(AccessTokenGuard).canActivate(route, state);
};
