<div
  class="card h-100 p-4 rounded overflow-hidden position-relative m-1"
  *transloco="let translated"
>
  <form class="form-group" [formGroup]="this.loginForm">
    <!--    Display error code when login by token failure-->
    @if (this.switchCaseErrorCode) { @switch (this.switchCaseErrorCode) { @case
    (0) { } @case (ERROR_CODE.AccountNotAllowLoginWithTypeError) {
    <div class="alert alert-danger" role="alert">
      <strong
        transloco="auth.component.login.errors.AccountNotAllowLoginWithTypeError"
      >
        Loại tài khoản không hợp lệ: Vui lòng chọn loại tài khoản đúng với tài
        khoản của bạn
      </strong>
    </div>
    } @case (ERROR_CODE.AccessTokenExpiredError) {
    <div class="alert alert-danger" role="alert">
      <strong transloco="auth.component.login.errors.AccessTokenExpiredError">
        Phiên đăng nhập này đã hết hạn, vui lòng thực hiện đăng nhập để tiếp tục
        sử dụng ứng dụng
      </strong>
    </div>
    } @case (ERROR_CODE.AccountTypeAndTokenNotMatchError) {
    <div class="alert alert-danger" role="alert">
      <strong
        transloco="auth.component.login.errors.AccountTypeAndTokenNotMatchError"
      >
        Liên kết không hợp lệ: Tài khoản sinh viên không thể đăng nhập vào cổng
        thông tin giành cho nhân viên và ngược lại
      </strong>
    </div>
    } @case (ERROR_CODE.AccountInactiveError) {
    <div class="alert alert-danger" role="alert">
      <strong transloco="auth.component.login.errors.AccountInactiveError">
      </strong>
      <u (click)="navigateToActiveAccountPage()" style="cursor: pointer">
        <i>
          <b>
            <br />
            Gửi lại tôi đường dẫn kích hoạt tài khoản
          </b>
        </i>
      </u>
    </div>
    } @case (ERROR_CODE.AccountLockedError) {
    <div class="alert alert-danger" role="alert">
      <strong transloco="auth.component.login.errors.AccountLockedError">
      </strong>
    </div>
    } @case (ERROR_CODE.AccountNotExistedError) {
    <div class="alert alert-danger" role="alert">
      <strong transloco="auth.component.login.errors.AccountNotExistedError">
      </strong>
    </div>
    } @case (ERROR_CODE.WrongUsernameOrPasswordError) {
    <div class="alert alert-danger" role="alert">
      <strong
        transloco="auth.component.login.errors.WrongUsernameOrPasswordError"
      >
      </strong>
    </div>
    } @case (ERROR_CODE.NotAllowAccountUseAppError) {
    <div class="alert alert-danger" role="alert">
      <strong
        transloco="auth.component.login.errors.NotAllowAccountUseAppError"
      >
        Tài khoản của bạn không được phép sử dụng ứng dụng này
      </strong>
    </div>
    } @case (ERROR_CODE.AccountNotAllowUseFeatureError) {
    <div class="alert alert-danger" role="alert">
      <strong
        transloco="auth.component.login.errors.AccountNotAllowUseFeatureError"
      >
        Tài khoản của bạn không được phép sử dụng tính năng này
      </strong>
    </div>
    } @default {
    <div class="alert alert-danger" role="alert">
      <strong>
        Lỗi kỹ thuật: Không thể đăng nhập tự động, vui lòng đăng nhập lại để
        tiếp tục sử dụng ứng dụng
      </strong>
    </div>
    } } }
    <!--    Display error code when login by token failure-->

    <!-- #Username -->
    <div class="form-group">
      <label class="form-label">
        {{ translated("auth.component.login.form.usernameLabel") }}:
        <span class="text-danger"> (*)</span>
      </label>
      <input
        #autoFocusWhenLoginFailed zaaAutofocus
        formControlName="username"
        type="email"
        id="username"
        class="form-control form-control-lg"
        placeholder=""
      />
      @if (this.loginForm.get('username')?.hasError('required') &&
      this.showErrorWhenSubmit) {
      <div
        class="invalid-feedback d-block"
        transloco="auth.component.login.form.userNameShouldNotBeEmpty"
      >
        Tên đăng nhập không được để trống
      </div>
      }
    </div>

    <!-- #Password -->
    <div class="mb-2">
      <app-input-password
        [class]="'form-control form-control-lg'"
        [disableCheck]="true"
        (changePassword)="this.loginForm.get('password')?.setValue($event)"
      ></app-input-password>
    </div>

    @if (ENABLE_CHOOSE_ACCOUNT_TYPE && this.ACCOUNT_TYPES) {
    <mat-form-field class="w-100 mt-1 mb-2">
      <mat-label>
        {{ translated("auth.component.login.form.chooseAccountType") }}
      </mat-label>
      <mat-select [formControl]="$any(this.loginForm.get('typeAccount'))">
        @for (food of ACCOUNT_TYPES; track food) { @if (food.show) {
        <mat-option [value]="food.id">
          @if (food.enableLanguage) {
          {{ translated(food.displayText) }}
          } @else {
          {{ food.displayText }}
          }
        </mat-option>
        } }
      </mat-select>
    </mat-form-field>
    } @if (!this.loginForm.get('reCapcha')?.value) {
    <div class="w-100">
      <re-captcha
        (resolved)="resolvedRecapCha($event)"
        [siteKey]="this.RECAPCHA_SECRET_KEY"
      >
      </re-captcha>
    </div>
    }

    @if(this.ENABLE_FORGOT_PASSOWRD) {
    <div class="row mt-2">
      <div class="col-auto mr-auto"></div>
      <div class="col-auto ml-auto">
        <span
          (click)="this.navigateToForgotPassword()"
          class="text-primary font-weight-bold"
          ><u
            ><a>{{
              translated("auth.component.login.form.forgotPassword")
            }}</a></u
          ></span
        >
      </div>
    </div>
  }
    <div class="row">
      <div class="col">
        <hr />
      </div>
    </div>

    <!-- #Submit -->
    <div class="row form-group">
      <div class="col">
        <button
          class="btn btn-primary btn-block btn-lg waves-effect waves-themed d-flex justify-content-center align-items-center"
          (click)="onLogin()"
          [disabled]="this.loginForm.disabled || this.isWaitingForLogin"
        >
          <span
            *ngIf="this.isWaitingForLogin"
            class="spinner-border spinner-border-sm mr-2"
            role="status"
            aria-hidden="true"
          >
          </span>
          <strong transloco="auth.component.login.form.loginButton"
            >Đăng nhập</strong
          >
        </button>
      </div>
    </div>
  </form>
  @if (this.ENABLE_LOGIN_BY_GOOGLE) {
  <div class="d-flex justify-content-center mb-4">
    <span transloco="auth.component.login.form.or">Hoặc</span>
  </div>
  <app-login-by-google> </app-login-by-google>
  } @if (this.ENABLE_LOGIN_BY_FACEBOOK) {
  <app-login-by-facebook></app-login-by-facebook>
  } @if (this.ENABLE_REGISTER) {
  <div class="row no-gutters justify-content-center mt-3">
    <span class="mr-1" transloco="auth.component.login.form.registerLinkLabel"
      >Chưa có tài khoản?</span
    >
    <a
      class="hover-underline"
      (click)="this.navigateToRegister()"
      href="javascript:void(0)"
    >
      {{ translated("auth.component.login.form.registerActionLink") }}
    </a>
  </div>
  }
</div>
