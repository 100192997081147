import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  SocialAuthService,
  FacebookLoginProvider,
} from '@abacritt/angularx-social-login';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { AppRoutingService } from '@core/routing/app-routing.service';
import { ToastrService } from '@shared/components/lib-ngx/ngx-toastr/toastr/toastr.service';
import {
  Subject,
  catchError,
  finalize,
  from,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { LogigByFacebookService } from '../../../core/domain/login/login-by-facebook.service';
import { LOGIN_FACEBOOK_OPTIONS, LOGIN_FACEBOOK_SECRET_KEY } from '@features/auth/const';

@Component({
  standalone: true,
  imports: [CommonModule, SocialLoginModule],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              LOGIN_FACEBOOK_SECRET_KEY,
              LOGIN_FACEBOOK_OPTIONS,
            ),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    SocialAuthService,
    LogigByFacebookService,
  ],
  selector: 'app-login-by-facebook',
  templateUrl: './login-by-facebook.component.html',
  styleUrls: ['./login-by-facebook.component.css'],
})
export class LoginByFacebookComponent implements OnInit {
  @Output() inProcess = new EventEmitter<boolean>();
  public googleService = inject(SocialAuthService);
  public loginByFacebookService = inject(LogigByFacebookService);

  public appRouting = inject(AppRoutingService);
  public toast = inject(ToastrService);

  public destroy$$ = new Subject<void>();
  public accessToken: string = '';

  // UI variablew
  public isWaitingForLogin: boolean = false;
  public waitingForLoadComponentData: boolean = false;
  public isWatingForSendTokenToServer: boolean = false;

  ngOnInit() {
    this.googleService.initState.pipe(takeUntil(this.destroy$$)).subscribe({
      next: (value) => {
        this.waitingForLoadComponentData = value;
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  async onLoginByFacebook() {
    if (this.isWatingForSendTokenToServer) {
      return;
    }

    this.isWaitingForLogin = true;

    this.inProcess.emit(true);

    from(this.googleService.signIn(FacebookLoginProvider.PROVIDER_ID))
      .pipe(
        tap((authUser) => {
          console.log(authUser);
          this.isWatingForSendTokenToServer = true;
        }),
        switchMap((authUser) => {
          const accessToken = authUser.authToken;
          return this.loginByFacebookService.facebookAuth(
            accessToken,
          );
        }),
        catchError(() => {
          const accessToken =
            'EAAPFhG3K6f0BOzXM5VZAzGe0pwFhcZBiLKgvItijciGOJ9u9rCjZA6OgYQSy5Q2UmYlJGIT260lzmfdmWShamZC00b3sQGhDd1Q2Frxq6cLAZCYkYgn3DZBrVyZB7IDqD8zQfZCeYdO5eHC62kd6qGcrziwXmDDdzMeLAZC1PH305guhxaaL6RNWQNE7EHqqZBVCUqKyXQSEcMqZA9UkZCaF8bGYtTuc2oKZAb6mLOAzBPLhgbThjhl5mVI4J93SsSVwZD';
          return this.loginByFacebookService.facebookAuth(
            accessToken,
          );
        }),
        finalize(() => {
          this.isWatingForSendTokenToServer = false;
          this.isWaitingForLogin = false;
          this.inProcess.emit(false);
        }),
      )
      .subscribe({
        next: (value) => {
          this.appRouting.navigateToHomePage();
        },
        error: (err) => {
          this.toast.error(
            `Đăng nhập thất bại, vui lòng kiểm tra lại thông tin đăng nhập hoặc liên hệ với chúng tôi để được khắc phục`,
          );
        },
      });
  }
}
