import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { RecaptchaModule } from 'ng-recaptcha';
import { LoginByGoogleComponent } from '../login-by-google/login-by-google.component';

import { LoginByFacebookComponent } from '../login-by-facebook/login-by-facebook.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { lastValueFrom } from 'rxjs';
import { InputPasswordComponent } from '@features/auth/shared/components/input-password/input-password.component';
import { AppRoutingService } from '@core/routing/app-routing.service';
import { AuthVersion1Service } from '@features/auth/core/domain/login/auth-version-1.service';
import { ZaaAutofocusDirective } from '@shared/directives/zaa-autofocus/zaa-autofocus.directive';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    TranslocoModule,
    RecaptchaModule,
    LoginByGoogleComponent,
    LoginByFacebookComponent,
    InputPasswordComponent,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,

    ZaaAutofocusDirective,
  ],
  selector: 'app-login-form-v1',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
})
export class LoginFormV1Component implements OnInit {
  constructor(
    public authService: AuthVersion1Service,
    public readonly translocoService: TranslocoService,
    public readonly router: AppRoutingService,
    private readonly fb: FormBuilder
  ) { }

  // Component data variable
  loginForm!: FormGroup;
  _HELPER_LOGIN_FAILURE_LINK = ''
  @ViewChild('autoFocusWhenLoginFailed') autoFocusWhenLoginFailed!: ElementRef;

  // Ui variable
  isWaitingForLogin = false;
  showErrorLabelInput: boolean = false;
  errorMessage: string = '';

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: [false]
    },);

    this.loginForm.valueChanges.subscribe(() => {
      if (this.errorMessage && this.showErrorLabelInput) {
        this.showErrorLabelInput = false;
      }
    })
  }

  async onLogin() {
    if (this.isWaitingForLogin || this.loginForm.invalid) {
      this.onFocusLogin();
      return;
    }

    this.isWaitingForLogin = true;
    const { username, password } = this.loginForm.value;

    try {
      const loginResult = await lastValueFrom(
        this.authService.login(username, password)
      );
      if (loginResult.token) {
        this.router.navigateToPreviousPage();
      }
    } catch (error: any) {
      this.errorMessage = error?.message;
      this.showErrorLabelInput = true;
      this.isWaitingForLogin = false;
      this.onFocusLogin();
    }
  }

  onFocusLogin() {
    if(!this.autoFocusWhenLoginFailed.nativeElement)  return;
    this.autoFocusWhenLoginFailed.nativeElement.focus();
  }
}
