import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { API_ENDPOINTS } from '@config/api.config';
import { catchError, throwError, map, tap } from 'rxjs';
import { AuthModel } from './models/version-2/auth.model-verssion2';
import { ILoginByFaceBook } from './models/login-3th.interface';
import { AuthService } from './auth-version-2.service';
import { AuthErrorModel } from './models/version-2/auth-error-version2.model';

@Injectable()
export class LogigByFacebookService implements ILoginByFaceBook {
  public httpClient = inject(HttpClient);
  public authService = inject(AuthService);

  FB_ENDPOINT = `/user/enter-system/login-facebook`;

  facebookAuth(accessToken: string) {
    const requestBody = {
      accessToken: accessToken,
    };

    return this.httpClient.post(this.FB_ENDPOINT, requestBody).pipe(
      catchError((err) => {
        return throwError(() => AuthErrorModel.handle(err));
      }),
      map((response: any) => {
        return new AuthModel().mapDataFromAPI(response);
      }),
      tap((formated) => {
        this.authService.setAuthResponseToStorage(
          formated.token,
          formated.result,
          formated.listmenu_active
        );
      })
    );
  }
}
