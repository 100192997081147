import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { CoreAuthConfigService } from '@features/auth/core-auth-config.service';
import { LoginFormV1Component } from './login-form-auth-v1/login-form.component';
import { LoginFormV2Component } from './login-form-auth-v2/login-form.component';
import { AuthVersion } from '@features/auth/const';

@Component({
  standalone: true,
  imports: [SharedModule, CommonModule, RouterModule, LoginFormV1Component, LoginFormV2Component],
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  conf = inject(CoreAuthConfigService);
  isV1 = this.conf.applicationConfig.authVersion == AuthVersion.VERSION_1 ;
}
