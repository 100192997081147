import { Routes } from '@angular/router';
import { LoginComponent } from './implements/login/login.component';
import { RegisterComponent } from './implements/register/register-form/register.component';
import { CanActivateAccessTokenGuard } from './core/guards/access-token.guard';

export const authRoutes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    canActivate: [CanActivateAccessTokenGuard],
    path: 'cap-nhat-thong-tin',
    loadChildren: () =>
      import('./public-api').then((m) => m.VerifyAccountFirstLoginModule),
  },
  {
    path: 'quen-mat-khau',
    loadComponent: () =>
      import('./public-api').then((c) => c.ForgotPasswordComponent),
  }
];
