<button
        id="login-by-facebook-button"
        class="btn btn-info btn-block btn-lg waves-effect waves-themed mt-1"
        type="submit"
        (click)="this.onLoginByFacebook()">
  @if(this.isWatingForSendTokenToServer) {
  <span
        class="spinner-border spinner-border-sm mr-2"
        role="status"
        aria-hidden="true">
  </span>
  }
  <i class="fab fa-facebook mr-1"></i>
  <span>Đăng nhập bằng Facebook</span>
</button>
