<button
        id="login-by-google-button"
        class="btn btn-info btn-block btn-lg waves-effect waves-themed"
        type="submit"
        (click)="this.onLoginByGoogle()">
  @if(this.isWatingForSendTokenToServer) {
  <span
        class="spinner-border spinner-border-sm mr-2"
        role="status"
        aria-hidden="true">
  </span>
  }
  <i class="fab fa-google mr-1"></i>
  <span >Đăng nhập bằng Google</span>
</button>

<div id="tt_d123"></div>
