import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { catchError, map, tap, throwError } from 'rxjs';
import { AuthModel } from './models/version-2/auth.model-verssion2';
import { ILoginByGoogle } from './models/login-3th.interface';
import { AuthErrorModel } from './models/version-2/auth-error-version2.model';
import { AuthService } from './auth-version-2.service';

@Injectable()
export class LoginByGoogleService implements ILoginByGoogle {
  public httpClient = inject(HttpClient);
  public authService = inject(AuthService);

  GOOGLE_END_ACCESS_TOKEN_ENDPOINT = `/user/enter-system/login-gmail`;

  constructor() {}

  googleAuth(accessToken: string) {
    const requestBody = {
      access_token: accessToken,
    };

    return this.httpClient
      .post(this.GOOGLE_END_ACCESS_TOKEN_ENDPOINT, requestBody)
      .pipe(
        catchError((err) => {
          return throwError(() => AuthErrorModel.handle(err));
        }),
        map((response: any) => {
          return new AuthModel().mapDataFromAPI(response);
        }),
        tap((formated) => {
          this.authService.setAuthResponseToStorage(
            formated.token,
            formated.result,
            formated.listmenu_active
          );
        })
      );
  }
}
