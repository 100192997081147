<div class="col-xl-12">
  <h2 class="fs-xxl fw-500 mb-5 text-white text-center">
    Đăng ký tài khoản
  </h2>
</div>
<div class="col-xl-6 ml-auto mr-auto">
  <div class="card p-4 rounded-plus bg-faded">

    <!-- <div class="alert alert-primary text-dark" role="alert">
      <strong>Chú ý!</strong>
      Due to server maintenance from 9:30GTA
      to 12GTA, the verification emails could be delayed by up to 10
      minutes.
    </div> -->

    <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
      <div class="form-group row">
        <label class="col-xl-12 form-label"
               for="firstName">
          Họ và tên của bạn
          <span class="text-danger"> (*)</span>
        </label>

        <div class="col-4 pr-1">
          <input type="text"
                 formControlName="firstName"
                 id="firstName"
                 class="form-control"
                 placeholder="Họ"
                 required>
          <div *ngIf="registrationForm.get('firstName')?.hasError('required')
               && this.registrationForm.get('firstName')?.touched"
               class="invalid-feedback d-block">
            Họ không được để trống
          </div>
        </div>

        <div class="col-4 pl-1 pr-1">
          <input type="text"
                 formControlName="middleName"
                 id="middleName"
                 class="form-control"
                 placeholder="Tên lót">
        </div>

        <div class="col-4 pl-1">
          <input type="text"
                 formControlName="lastName"
                 id="lastName"
                 class="form-control"
                 placeholder="Tên"
                 required>
          <div
               *ngIf="registrationForm.get('lastName')?.hasError('required')
               && this.registrationForm.get('lastName')?.touched"
               class="invalid-feedback d-block">
            Tên không được để trống
          </div>
        </div>
      </div>

      <!-- INPUT EMAIL REGION -->
      <div class="form-group">
        <label class="form-label" for="userpassword">Email Đăng nhập
          <span class="text-danger"> (*)</span>
        </label>
        <input type="email"
               formControlName="email"
               id="userpassword"
               class="form-control"
               required>

        <ng-container *ngIf="registrationForm.get('email')?.touched">
          <div *ngIf="registrationForm.get('email')?.hasError('required')"
               class="invalid-feedback d-block">
            Email đăng nhập không được để trống
          </div>

          <div *ngIf="registrationForm.get('email')?.hasError('email')"
               class="invalid-feedback d-block">
            Vui lòng nhập đúng định dạng địa chỉ email <strong>{{ 'vidu@gmail.com'}}</strong>
          </div>

          <div *ngIf="this.showAccountAlreadyExistError"
               class="invalid-feedback d-block">
            Tài khoản này đã tồn lại, vui lòng nhập tên tài khoản khác
          </div>

          <div *ngIf="this.showInActiveAccountError"
               class="invalid-feedback d-block">
            Tài khoản này đã được đăng ký trước đó nhưng chưa được kích hoạt
          </div>

          <div *ngIf="this.showLockedAccountError"
               class="invalid-feedback d-block">
            Tài khoản này đã bị khóa
          </div>
        </ng-container>
      </div>
      <!--END INPUT EMAIL REGION -->

      <div class="form-group">
        <app-input-password [value]="this.registrationForm.get('password')?.value ?? ''"
                            (changePassword)="this.onChangePassword($event)">
        </app-input-password>
      </div>

      <!-- 2 CÁI CHECK BOX NE -->
      <div class="form-group demo mb-5">
        <div class="custom-control custom-checkbox">
          <input type="checkbox"
                 formControlName="terms"
                 class="custom-control-input"
                 id="terms"
                 required>
          <label class="custom-control-label cursor-pointer"
                 for="terms"> Tôi đồng ý với việc thu thập và lưu trữ dữ
            liệu cá nhân theo
            <a [href]="this.policyLink" target="_blank">
              <u>
                cam kết bảo mật
              </u>
            </a>
          </label>
        </div>

        <div class="custom-control custom-checkbox">
          <input type="checkbox"
                 formControlName="newsletter"
                 class="custom-control-input"
                 id="newsletter"
                 required>
          <label class="custom-control-label cursor-pointer"
                 for="newsletter">
            Tôi muốn nhận thông báo về các ấn phẩm và tin tức mới nhất của Tạp chí
          </label>
        </div>
      </div>
      <div class="form-group">
        <re-captcha (resolved)="resolved($event)"
                    [siteKey]="this.reCapchaSecretKey">
        </re-captcha>
      </div>
      <!-- 2 CÁI CHECK BOX NE -->

      <!-- # Submit -->
      <div class="row no-gutters justify-content-start justify-content-sm-center">
        <button type="button"
                (click)="this.navigateToLoginPage()"
                [disabled]="this.waitingToRegis"
                class="mr-3 col-3 btn-lg btn btn-secondary waves-effect waves-themed">
          Quay lại
        </button>

        <button type="submit"
                [disabled]="this.registrationForm.invalid || this.waitingToRegis"
                class="col col-md-4 btn-lg btn btn-danger waves-effect waves-themed">
          <span *ngIf="this.waitingToRegis"
                class="spinner-border spinner-border-sm mr-1" role="status"
                aria-hidden="true">
          </span>
          Đăng ký
        </button>
      </div>
    </form>
  </div>
</div>
