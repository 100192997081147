import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { RecaptchaModule } from 'ng-recaptcha';
import { LoginByGoogleComponent } from '../login-by-google/login-by-google.component';

import { LoginByFacebookComponent } from '../login-by-facebook/login-by-facebook.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { Subject, takeUntil } from 'rxjs';
import { InputPasswordComponent } from '@features/auth/shared/components/input-password/input-password.component';
import { AppRoutingService } from '@core/routing/app-routing.service';
import { languagePathBuilder } from '@features/auth/shared/languages';
import { CoreAuthConfigService } from '@features/auth/core-auth-config.service';
import { ACCOUNT_TYPES } from '@features/auth/const';
import { AuthService } from '@features/auth/core/domain/login/auth-version-2.service';
import { ERROR_CODE } from './conf-login-form';
import { ZaaAutofocusDirective } from '@shared/directives/zaa-autofocus/zaa-autofocus.directive';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    TranslocoModule,
    RecaptchaModule,
    LoginByGoogleComponent,
    LoginByFacebookComponent,
    InputPasswordComponent,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,

    ZaaAutofocusDirective,
  ],
  selector: 'app-login-form-v2',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
})
export class LoginFormV2Component implements OnInit, OnDestroy, AfterViewInit {
  private conf = inject(CoreAuthConfigService);

  constructor(
    public authService: AuthService,
    public readonly router: AppRoutingService,
    private readonly fb: FormBuilder,
    private readonly route: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  ngAfterViewInit(): void {
    if (
      this.redirectMode == 'logout' ||
      this.switchCaseErrorCode == ERROR_CODE.AccessTokenExpiredError
    ) {
      this.authService.clearLocalStorageItem();
    }
  }

  public _LOGIN_COUNT_ERROR_LIMIT_TO_ENABLE_CAPCHA =
    this.conf.defaultAuthConfig.reCaptcha?.enableReCaptchaAfterError ?? 10;
  public RECAPCHA_SECRET_KEY =
    this.conf.defaultAuthConfig.reCaptcha?.secretKey ?? '';

  public ENABLE_LOGIN_BY_GOOGLE =
    this.conf.socicalNetworkAuthConfig.google?.enable;

  public ENABLE_LOGIN_BY_FACEBOOK =
    this.conf.socicalNetworkAuthConfig.facebook?.enable;

  public ENABLE_CHOOSE_ACCOUNT_TYPE =
    this.conf.defaultAuthConfig.typeAccount?.enable;

  public ENABLE_FORGOT_PASSOWRD =
    this.conf.defaultAuthConfig.forgotPassword?.enable ?? false;
  public ENABLE_REGISTER =
    this.conf.defaultAuthConfig.register?.enable ?? false;
  public ACCOUNT_TYPES: Array<any> = ACCOUNT_TYPES;

  // Component data variable
  public loginForm!: FormGroup;

  // Ui variable
  public isWaitingForLogin = false;
  public showErrorLabelInput: boolean = false;
  public showErrorWhenSubmit: boolean = false;

  // Login by token
  destroy$$ = new Subject<void>();

  public switchCaseErrorCode!: number;
  public ERROR_CODE = ERROR_CODE;

  @ViewChild('autoFocusWhenLoginFailed') autoFocusWhenLoginFailed!: ElementRef;

  redirectMode = '';

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroy$$))
      .subscribe((params) => {
        this.switchCaseErrorCode = +params['er'];
        this.redirectMode = params['type']; //logout
      });

    let defaultTypeAccount = '';

    if (this.ACCOUNT_TYPES) {
      defaultTypeAccount = (this.ACCOUNT_TYPES as any)?.filter(
        (e: any) => e?.default
      )[0].id;
    }

    console.log(defaultTypeAccount);

    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: [false],
      typeAccount: [defaultTypeAccount, Validators.required],
      reCapcha: [true, Validators.requiredTrue],
    });

    this.loginForm.valueChanges.subscribe(() => {
      if (this.showErrorLabelInput) {
        this.showErrorLabelInput = false;
      }
    });
  }

  onLogin() {

    if (this.isWaitingForLogin || this.loginForm.invalid) {
      this.onFocusLogin();
      return;
    }

    if (this.loginForm.invalid) {
      this.showErrorWhenSubmit = true;
    }
    if (this.isWaitingForLogin || this.loginForm.invalid) {
      return;
    }

    this.isWaitingForLogin = true;
    this.switchCaseErrorCode = 0;

    const { username, password, typeAccount } = this.loginForm.value;

    this.authService.login(username, password, typeAccount).subscribe({
      next: (value) => {
        this.route.navigate(['cap-nhat-thong-tin']);
      },
      error: (err) => {
        setTimeout(() => {
          this.switchCaseErrorCode = +err.message;
          this.showErrorLabelInput = true;
          this.isWaitingForLogin = false;
        }, 400);
        this.onFocusLogin();
      },
    });
  }

  pathLanguageBuilder = (path: string[]) => {
    return languagePathBuilder(['component', 'login', 'errors', ...path]);
  };

  showReCapcha() {
    return (
      this.authService.getLoginCountError() >=
      this._LOGIN_COUNT_ERROR_LIMIT_TO_ENABLE_CAPCHA
    );
  }

  setApp($event: any) {
    this.loginForm.get('app')?.setValue($event);
  }

  resolvedRecapCha(captchaResponse: string | null) {
    this.loginForm.get('reCapcha')?.setValue(true);
  }

  navigateToForgotPassword() {
    this.route.navigate(['quen-mat-khau']);
  }

  navigateToRegister() {
    this.route.navigate(['/register']);
  }

  // Đối với nghiệp vụ tạp chí phát triển hội nhập - lkích hoạt tài khoản là gửi email
  /// Còn với nhửng ứng dụng khác có thể gửi email hoăạc gửi OTP ...
  navigateToActiveAccountPage() {
    console.warn(`Feature need to implements`);
    // this.route.navigate([
    //   `/account-confirm/${this.loginForm.get('username')?.value}/send`,
    // ]);
  }

  onFocusLogin() {
    if(!this.autoFocusWhenLoginFailed.nativeElement)  return;
    this.autoFocusWhenLoginFailed.nativeElement.focus();
  }
}
