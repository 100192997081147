  <div   class="card h-100 p-4 rounded overflow-hidden position-relative m-1"
  >
    <form class="form-group pt-2" [formGroup]="this.loginForm">
      <label class="form-label" transloco="features.auth.login.username.label"></label>

      <input #autoFocusWhenLoginFailed zaaAutofocus formControlName="username" type="email" id="username" class="form-control form-control-lg"
        placeholder="" />
      <!-- <div class="help-block" transloco="features.auth.login.username.notifyMessage"></div> -->
      <!-- [class.is-valid]="this.loginForm.dirty && this.loginForm.get('username')?.valid"
        [class.is-invalid]="showErrorLabelInput||this.loginForm.dirty && this.loginForm.get('username')?.invalid" -->

      <div class="form-group">
        <label class="form-label mt-3" transloco="features.auth.login.password.label"> </label>
        <input formControlName="password" type="password" id="password" class="form-control form-control-lg"
          placeholder="" />
        <!-- <div class="help-block" transloco="features.auth.login.password.notifyMessage"></div> -->
        <!-- [class.is-valid]="this.loginForm.dirty &&  this.loginForm.get('password')?.valid"
          [class.is-invalid]="showErrorLabelInput || this.loginForm.dirty && this.loginForm.get('password')?.invalid" -->
      </div>

      <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
        <strong>Chú ý! </strong>{{ errorMessage }}
      </div>

      <div class="row form-group">
        <div class="col">
          <button
            class="btn btn-primary btn-block btn-lg waves-effect waves-themed d-flex justify-content-center align-items-center"
            [disabled]="this.isWaitingForLogin|| this.loginForm.invalid"
            [ngClass]="{
              'disabled cursor-not-allowed': this.isWaitingForLogin|| this.loginForm.invalid
            }"
            (click)="onLogin()">
            <span *ngIf="this.isWaitingForLogin" class="spinner-border spinner-border-sm mr-2" role="status"
              aria-hidden="true">
            </span>
            <strong transloco="features.auth.login.button.login">Đăng nhập</strong>
          </button>
        </div>
      </div>

    </form>
    <div class="row pb-2">
      <div class="col-12">
        <i class="fal fa-chevron-right mr-2">
        </i> Đăng nhập không được? <a
           [href]="_HELPER_LOGIN_FAILURE_LINK"
           target="_blank">
          Xem hướng dẫn tại đây
        </a>
      </div>
    </div>

</div>
