import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  SocialAuthService,
} from '@abacritt/angularx-social-login';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';

import { LoginByGoogleService } from '../../../core/domain/login/login-by-google.service';
import {
  Subject,
  finalize,
  from,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { AppRoutingService } from '@core/routing/app-routing.service';
import { ToastrService } from '@shared/components/lib-ngx/ngx-toastr/toastr/toastr.service';
import { LOGIN_GOOGLE_AUTH_CLIENT_ID, LOGIN_GOOGLE_OPTIONS } from '@features/auth/const';

@Component({
  standalone: true,
  imports: [CommonModule, SocialLoginModule],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              LOGIN_GOOGLE_AUTH_CLIENT_ID,
              LOGIN_GOOGLE_OPTIONS,
            ),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    SocialAuthService,
    LoginByGoogleService,
  ],
  selector: 'app-login-by-google',
  templateUrl: './login-by-google.component.html',
  styleUrls: ['./login-by-google.component.css'],
})
export class LoginByGoogleComponent implements OnInit, OnDestroy {
  @Output() inProcess = new EventEmitter<boolean>();

  public loginByGoogleService = inject(LoginByGoogleService);
  public googleService = inject(SocialAuthService);
  public appRouting = inject(AppRoutingService);
  public toast = inject(ToastrService);

  public destroy$$ = new Subject<void>();
  public accessToken: string = '';

  // UI variablew
  public isWaitingForLogin: boolean = false;
  public waitingForLoadComponentData: boolean = false;
  public isWatingForSendTokenToServer: boolean = false;

  ngOnInit() {
    this.googleService.initState.pipe(takeUntil(this.destroy$$)).subscribe({
      next: (value) => {
        this.waitingForLoadComponentData = value;
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  async onLoginByGoogle() {
    if (this.isWatingForSendTokenToServer) {
      return;
    }

    this.isWaitingForLogin = true;

    this.inProcess.emit(true);

    from(this.googleService.getAccessToken(GoogleLoginProvider.PROVIDER_ID))
      .pipe(
        tap((token) => {
          this.accessToken = token;
          this.isWatingForSendTokenToServer = true;
        }),
        switchMap((accessToken) => {
          return this.loginByGoogleService.googleAuth(accessToken);
        }),
        finalize(() => {
          this.isWatingForSendTokenToServer = false;
          this.isWaitingForLogin = false;
          this.inProcess.emit(false);
        }),
      )
      .subscribe({
        next: (value) => {
          this.appRouting.navigateToHomePage();
        },
        error: (err) => {
          this.toast.error(
            `Đăng nhập thất bại, vui lòng kiểm tra lại thông tin đăng nhập hoặc liên hệ với chúng tôi để được khắc phục`,
          );
        },
      });
  }
}
